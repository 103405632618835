body::-webkit-scrollbar {
  width: 0.6rem;
  background-color: rgba(0, 0, 0, 0.1);
}

body::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 0.5rem;
  height: 10px;
}
